


function NoPage() {
    return(
        <>
            <h1>No Such Page Exists :)</h1>
        </>
    )
}

export default NoPage;
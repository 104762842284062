import Banner from "./Banner";
import Footer from "./Footer";
import Navbar from "./Navbar";



function Home() {
    return(
        <>
            <Navbar />
            <Banner />
            <Footer />
        </>
    )
}


export default Home;